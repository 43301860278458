<template>
  <trazo-off-canvas
    :isOpen="isOpen"
    id="modal-employee"
    :selectorToggle="selectorToggle"
    v-on:close="closeModal"
    :title="textModal"
  >
    <template v-slot:header-buttons>
      <b-button size="sm" variant="light" @click="closeModal">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
      <b-button size="sm" variant="light" @click="onSubmit" :disabled="sending">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </template>
    <div class="justify-content-center">
      <form class="full" id="formSubproject" ref="formSubproject">
        <b-alert
          show
          variant="danger"
          v-bind:key="error"
          v-for="error in errors"
        >{{ error }}
        </b-alert
        >
        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Usuario"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(user)
            }"
          >
            <a-multiselect
              :disabled="edit"
              name="user"
              track-by="id"
              label="username"
              v-model="user"
              placeholder="Seleccione un usuario"
              :options="users"
              :allow-empty="false"
              @select="onSelect($event, 'user')"
            />
            <input type="hidden" name="user_hidden" v-model="user_hidden" />
          </div>
        </trazo-form-label-slot>
        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Roles"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(user_roles)
            }"
          >
            <a-multiselect
              name="user_roles"
              track-by="id"
              label="name"
              v-model="user_roles"
              :multiple="true"
              :hide-selected="true"
              placeholder="Seleccione"
              :options="roles"
              :allow-empty="false"
              @select="onSelect($event, 'user_roles')"
            />
            <input
              type="hidden"
              name="user_roles_hidden"
              v-model="user_roles_hidden"
            />
          </div>
        </trazo-form-label-slot>

        <trazo-form-input
          id="input-first_name"
          v-model="first_name"
          label="Nombre"
          name="first_name"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <trazo-form-input
          id="input-last_name"
          label="Apellido Paterno"
          name="last_name"
          :required="true"
          labelPosition="top"
          type="text"
          v-model="last_name"
        />
        <trazo-form-input
          id="input-last_name_2"
          v-model="last_name_2"
          label="Apellido Materno"
          name="last_name_2"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <trazo-form-label-slot labelPosition="top" label="Tipo de licencia">
          <b-form-select
            v-model="driver_license_type"
            :options="driver_license_types"
          ></b-form-select>
        </trazo-form-label-slot>

        <trazo-form-input
          id="input-license_number"
          v-model="license_number"
          name="license_number"
          label="Número de licencia"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <trazo-image-crop
          v-on:showModal="onShowModal"
          v-on:selectImage="selectPhoto"
        />
        <img
          class="mt-3"
          style="max-width: 300px;"
          v-if="imageToSend && !showImage"
          :src="imageToSend.dataUrl"
        />
        <img
          class="mt-3"
          v-if="scanned_signature && !imageToSend && !showImage"
          :src="scanned_signature"
        />
      </form>
    </div>
  </trazo-off-canvas>
</template>

<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoImageCrop from "@/view/content/TrazoImageCrop";
import ImageCompressor from "compressorjs";

function initialState() {
  return {
    showImage: false,
    textModal: "Empleado",
    user_hidden: "",
    user_roles_hidden: "",
    user: {},
    user_roles: [],
    code: "",
    description: "",
    abbreviation: "",
    driver_license_type: "-",
    license_number: "",
    first_name: "",
    last_name: "",
    last_name_2: "",
    scanned_signature: "",
    id: "",
    edit: false,
    errors: [],
    sending: false,
    driver_license_types: [
      { value: "-", text: "NO POSEE" },
      { value: "A-I", text: "A-I" },
      { value: "A-IIa", text: "A-IIa" },
      { value: "A-IIb", text: "A-IIb" },
      { value: "A-IIIa", text: "A-IIIa" },
      { value: "A-IIIb", text: "A-IIIb" },
      { value: "A-IIIc", text: "A-IIIc" },
      { value: "B-I", text: "B-I" },
      { value: "B-IIa", text: "B-IIa" },
      { value: "B-IIb", text: "B-IIb" },
      { value: "B-IIc", text: "B-IIc" }
    ],
    imageToSend: null
  };
}

export default Vue.component("modal-employee", {
  data() {
    return initialState();
  },
  props: [
    "objForm",
    "isOpen",
    "selectorToggle",
    "roles",
    "users",
    "enterprise"
  ],
  watch: {
    objForm(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.setData();
      }
    }
  },
  methods: {
    onShowModal(event) {
      this.showImage = event;
    },
    selectPhoto(image) {
      // let data = new FormData();
      this.imageToSend = image;
      new ImageCompressor(image.blob, {
        convertSize: Infinity,
        maxWidth: 300,
        maxHeight: 100,
        minWidth: 300,
        minHeight: 100,
        quality: 0.6,

        success: result => {
          console.log("new file", result);
          this.imageToSend.blob = result;
        },
        error(err) {
          console.log(err.message);
        }
      });
    },
    closeModal() {
      this.resetForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {
      });
      this.fv.off("core.form.invalid", () => {
      });
      this.fv.resetForm();
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        console.log("VALIDANDO EL FORMULARIOOO");
        const objeSaved = {
          employee: this.user_hidden,
          enterprise: this.enterprise,
          roles: this.user_roles.map(({ id }) => id)
        };

        this.errors = [];
        this.sending = true;
        ApiService.post(`api/user-enterprise/roles/add/`, objeSaved)
          .then(({ data }) => {
            ////////////////////////////////////
            /// GUARDAR LOS DATOS DEL USUARIO///
            ////////////////////////////////////
            console.log("PASANDO A ENVUIAR DATOS DE USUARIO")
            const objeSaved2 = new FormData();
            if (this.imageToSend) {
              objeSaved2.append(
                "scanned_signature",
                this.imageToSend.blob,
                this.imageToSend.name
              );
            }
            objeSaved2.append("first_name", this.first_name.trim());
            objeSaved2.append("last_name", this.last_name.trim());
            objeSaved2.append("last_name_2", this.last_name_2.trim());
            if (this.driver_license_type !== "-") {
              objeSaved2.append(
                "driver_license_type",
                this.driver_license_type
              );
              objeSaved2.append("license_number", this.license_number);
            }
            ApiService.patch(`api/user/${this.user.id}/`, objeSaved2).then(()=>{
              this.$emit("toast", {
                message: `Se ha ${
                  this.edit ? "editado" : "creado"
                } el item correctamente`,
                object: {
                  title: `Éxito`,
                  variant: "success",
                  solid: true
                }
              });
              this.$emit(this.edit ? "updated" : "created", data);
              this.closeModal();
              this.sending = false;
            });
            //////////////////////////////////////////////////////////////////////


          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach((object) => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$emit("toast", {
              message: "Ha ocurrido un error",
              object: {
                title: `Error`,
                variant: "danger",
                solid: true
              }
            });
          });




      });
      this.fv.on("core.form.invalid", () => {
        this.$emit("toast", {
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
    },
    setData() {
      console.log("LLAMANDO AL EVENTO SET DATA");
      if (!this.custom_lodash.isEmpty(this.objForm)) {
        this.id = this.objForm.id;
        this.user = this.objForm;
        this.user_hidden = this.objForm.id;
        this.user_roles = this.objForm.enterprise_roles.map((el) => {
          return this.roles.find((role) => role.id === el);
        });
        this.first_name = this.objForm.first_name;
        this.last_name = this.objForm.last_name;
        this.last_name_2 = this.objForm.last_name_2;
        this.driver_license_type = this.objForm.driver_license_type;
        this.license_number = this.objForm.license_number;
        this.scanned_signature = this.objForm.scanned_signature;
        console.log(" this.user_roles", this.user_roles);
        this.textModal = "EDITAR EMPLEADO";
        this.edit = true;
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          user_hidden: {
            validators: {
              notEmpty: {
                message: "El usuario es obligatorio"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    },
    // Validators
    isInvalidSelectNull(selectedItems) {
      return this.isTouchedForm && selectedItems === null;
    },
    onSelect(value, itemName) {
      this.$data[itemName] = value;
      if ("id" in value) {
        this.$data[`${itemName}_hidden`] = value.id;
      }
    },
    getUser(){
      ApiService();
    },
  },
  mounted() {
    this.setData();
    this.setupValidation();
    //this.getUser();
  },
  components: {
    TrazoOffCanvas,
    TrazoFormLabelSlot,
    TrazoFormInput,
    TrazoImageCrop
  }
});
</script>
