<template>
  <div>
    <portal to="subheader_actions" style="float: right;">
      <b-button v-b-modal.modal-form-enterprise @click="openModal" class="mr-2">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo empleado
      </b-button>
      <b-button v-if="mostrarFiltro" @click="listarTodo" class="mr-2" >
        <i class="flaticon2-list-2 icon-nm"></i>
        Listar Todo
      </b-button>
      <b-button v-if="mostrarFiltro" @click="openFilter" >
        <b-icon icon="Filter" aria-label="Help"></b-icon>
        Filtrar Por
      </b-button>
    </portal>
    <div v-if="!ocultarChip" class="row">
      <div class="col-md-12">
        <div class="cardChip">
          <filter-chip name="filtroChip" title="Filtros" color="#e1dffc" colorBackground="#f5f8ff" :filtersParent="filtros" :hidden="ocultarChip" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <div class="row mb-3 titulo">
            {{ enterprise_name }}
          </div>
          <div class="row mb-3 buscador">
            <div class="col ">
              <b-form-input
                id="filter-input"
                v-model="lupa"
                type="search"
                placeholder="Escriba para buscar"
              ></b-form-input>
            </div>
          </div>
          <b-card-text>
            <div>
              <b-table
                :filter="lupa"
                head-variant="dark"
                @filtered="onFiltered"
                :items="employees"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(full_name)="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                  {{ data.item.last_name_2 }}
                </template>
                <template #cell(is_active)="data">
                  <b-form-checkbox
                    v-model="data.item.is_active"
                    name="check-button"
                    switch
                    size="lg"
                    @change="
                      updateUserStatus(data.item.is_active, data.item.id)
                    "
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(actions)="data">
                  <b-col
                    class="h5 mb-0 flex justify-content-center"
                    style="width: 8rem"
                  >
                    <b-button
                      @click="editRoles(data)"
                      size="sm"
                      variant="light"
                    >
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                  </b-col>
                </template>
                <template #cell(roles)="data">
                  <fragment v-if="data.item.enterprise_roles">
                    <b-badge
                      v-bind:key="role"
                      v-for="role in data.item.enterprise_roles"
                      >{{ role }}
                    </b-badge>
                  </fragment>
                  <div v-else>No posee</div>
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>

    <modal-employee
      :isOpen="showModal"
      v-show="loaded"
      v-on:close="
        showModal = false;
        currentObject = null;
      "
      :objForm="currentObject"
      :users="notEmployees"
      :enterprise="enterprise"
      :roles="roles"
      v-on:updated="onUpdated"
      v-on:created="onCreated"
      v-on:toast="openToast"
    />
    <modal-filter
      class="margen"
      :isOpen="showFilter"
      v-show="loadedFilter"
      @confirmFilter="confirmFilter"
      v-on:close="
        showFilter = false; 
      "
      :filtersParent="filtros"
    />
  </div>
</template>

<script>

import Vue from "vue";
import ApiService from "@/core/services/api.service";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ModalEmployee from "./ModalEmployee";
import ModalFilter from "@/view/layout/filter/Filter";
import FilterChip from "@/view/layout/filter/FilterChip";
import helperFunctions from "@/functions";

export default Vue.component("employee-management", {
  data() {
    return {
      mostrarFiltro: false,
      enterprise_name: "",
      currentPage: 1,
      perPage: 12,
      loaded: false,
      showModal: false,
      currentObject: {},
      role: "",
      user: "",
      role_hidden: "",
      user_hidden: "",
      employees: [],
      enterprise: this.$route.params.enterprise,
      fields: [
        {
          key: "username",
          label: "DNI",
          sortable: true
        },
        {
          key: "full_name",
          label: "Apellidos y Nombres",
          sortable: true
        },
        {
          key: "roles",
          label: "Roles"
        },
        {
          key: "is_active",
          label: "¿Activo?"
        },
        {
          key: "actions",
          label: "Acciones"
        }
      ],
      id: "",

      users: [],
      roles: [],

      filter: null,
      rows: 0,
      
      lupa: '',
      showFilter: false,
      loadedFilter: false,
      ocultarChip: true,
      filtros: [
        {
          type: 'text',
          title: 'DNI',
          alias: 'dni',
          closable: false,
          expand: false,
          action: false,
          hidden: false,
          options: {
            model: '',
            disabled:  false,
            placeholder: 'Documento Nacional de Identidad',
          }
        },
        {
          type: 'text',
          title: 'APELLIDOS Y NOMBRES',
          alias: 'nombres',
          closable: false,
          expand: false,
          action: false,
          hidden: false,
          options: {
            model: '',
            disabled:  false,
            placeholder: 'Descripción',
          }
        },
        {
          type: 'select',
          multiple: true,
          title: 'ROLES',
          alias: 'roles',
          closable: false,
          expand: false,
          action: true,
          hidden: false,
          options: {
            model: '',
            values: [],
            options: [],
            searchable: true,
            filterable: true,
            disabled: false,
            clearable: true,
            label: 'label',
            placeholder: 'Seleccione Rol(es)'
          }
        },
        {
          type: 'radio',
          title: 'ESTADO',
          alias: 'estado',
          closable: false,
          expand: false,
          action: false,
          options: {
            model: 'ACTIVO',
            value: 'ACTIVO',
            count: [
              {
                value: 'ACTIVO',
                label: 'ACTIVO'
              },
              {
                value: 'INACTIVO',
                label: 'INACTIVO'
              }
            ]
          }
        }
      ],

    };
  },
  computed: {
    notEmployees() {
      return this.users.filter(user => {
        return !this.employees.find(employee => {
          return user.id === employee.id;
        });
      });
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    updateUserStatus(value, id) {
      console.log(value, id);
      ApiService.patch(`api/user/${id}/`, { is_active: value }).then(res =>
        console.log(res)
      );
    },
    getRoles() {
      ApiService.get("api/role").then(({ data }) => {
        this.roles = data.roles;
      });
    },
    getUsersFiltro() {
      try {
        ApiService.get("api/role").then(({ data }) => {
          let response = data.roles.map((e) => {
            return {
              id: e.id,
              codigo: e.id,
              label: e.name,
            };
          });
          this.filtros = helperFunctions.insertDataSelectFilter(this.filtros, 'roles', response, '');
        });
      }catch (error) {
        throw new Error(error);
      }
    },
    getUsers() {
      ApiService.get("api/user").then(({ data }) => {
        this.users = data;
      });
    },
    getEmployees() {
      ApiService.get(`/api/enterprise/${this.enterprise}/employees`).then(
        ({ data }) => {
          this.employees = data;
          this.rows = this.employees.length;
        }
      );
    },
    getEnterprise() {
      ApiService.get(`/api/enterprise/${this.enterprise}`).then(({ data }) => {
        this.enterprise_name = data.name;
      });
    },
    edit({ item }) {
      this.user = item.employee;
      this.role = this.roles.filter(el => el.id === item.role)[0];
      this.user_hidden = item.employee.id;
      this.role_hidden = item.role.id;
      this.id = item.id;
    },
    editRoles({ item }) {
      this.currentObject = item;
      this.openModal();
      console.log("item", item);
    },
    // Modal
    onCreated() {
      this.getEmployees();
      // this.employees.unshift(object);
    },
    onUpdated() {
      this.getEmployees();
    },
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    openModal() {
      if (!this.loaded)
        setTimeout(() => {
          this.showModal = true;
        }, 50);
      else this.showModal = true;
      this.loaded = true;
    },

    openFilter(){
      if (!this.loadedFilter)
        setTimeout(() => {
          this.showFilter = true;
        }, 50);
      else this.showFilter = true;
      this.loadedFilter = true;
    },

    async confirmFilter(filters) {
      this.showFilter = false
      this.filtros = filters
      this.ocultarChip = helperFunctions.hiddenChipFilter(this.filtros);
      this.listar();
    },

    listarTodo(){
      this.filtros = helperFunctions.filterCleanAll(this.filtros);
      this.ocultarChip = helperFunctions.hiddenChipFilter(this.filtros);
    },

    listar(){
      let obj = helperFunctions.mapFilters(this.filtros);
      console.log("filtros=>",obj);
    },
  },
  mounted() {
    this.getEmployees();
    this.getRoles();
    this.getUsers();
    this.getUsersFiltro();
    this.getEnterprise();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Empresa" },
      { title: "Mantenimiento de empleados" }
    ]);
  },
  components: {
    ModalEmployee,
    ModalFilter,
    FilterChip
  }
});
</script>

<style>

.margen{
  margin-top: 5rem;
}
.cardChip{
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: .5rem;
}

.buscador{
  float: right;
}
.titulo{
  float: left;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 500;
}

</style>